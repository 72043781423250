import React, { useEffect, useRef, useState } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

import GetImage from 'components/functional/getImage'
import { media } from 'utils/Media'
import Layout from 'components/global/layout'

const SinglePhotoStyles = styled.div`
  width: 100%;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  aspect-ratio: 1/1;
  position: relative;
  .gatsby-image-wrapper {
    transition: all 0.25s ease-in-out;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    /* img {
      object-fit: fill !important;
    } */
  }
  &:hover {
    transform: scale(1.05);
    .gatsby-image-wrapper {
    }
  }
  .overlay {
    position: absolute;
    /* height: 100%; */
    left: 1rem;
    /* width: 100%; */

    bottom: 1.5rem;
    > div {
      /* width: 90%;
      height: 100%;
      margin: var(--auto);*/
      color: var(--white);
      text-shadow: 2px 2px #000;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      /* p {
        margin-bottom: 1rem;
      } */
    }
  }
  /* @media ${media.xl} {
    grid-column: span 2;
    &:nth-last-child(1) {
      grid-row-start: 5;
      grid-column: 2 / span 2;
    }
    &:nth-last-child(2) {
      grid-column: 4 / span 2;
    }
  } */
`
const GalleryStyles = styled.div`
  width: 100%;
  padding: 5rem 0 0rem 0;
  > section {
    width: 90%;
    margin: 2rem auto;
    max-width: 1290px;
    > div {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1.5rem;
      @media ${media.md} {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
        margin: 3.5rem auto;
      }
      @media ${media.xl} {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2rem;
      }
    }
  }
`
const SingleSliderStyles = styled.section`
  width: 100%;
  height: 100vh;
  position: relative;
  .mobile {
    width: 100%;
    height: 100%;
    @media ${media.md} {
      display: none;
    }
  }
  .desktop {
    display: none;

    @media ${media.md} {
      width: 100%;
      height: 100%;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      .gatsby-image-wrapper {
        margin-bottom: 7.5rem;
        width: 1500px;
        height: auto;
        z-index: -2;
      }
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    z-index: -2;
  }
`
const LeftArrow = styled.div`
  cursor: pointer;
  fill: rgba(255, 255, 255, 0.45);
  width: 45px;
  height: 45px;
  position: absolute;
  left: 5px;
  top: 45%;
  &:hover,
  &:active {
    fill: var(--main);
  }
  @media ${media.lg} {
    left: 1rem;
  }
`
const RightArrow = styled.div`
  cursor: pointer;
  fill: rgba(255, 255, 255, 0.45);
  width: 45px;
  height: 45px;
  transform: scale(-1);
  position: absolute;
  right: 5px;
  top: 45%;
  &:hover,
  &:active {
    fill: var(--main);
  }
  @media ${media.lg} {
    right: 1rem;
  }
`
const GalleryAgain = ({ data, location }) => {
  const sliderRef = useRef()
  const { gallery } = data
  const options = {
    settings: {
      overlayColor: 'rgba(0, 0, 0, .95)',
      autoplaySpeed: 2500,
      transitionSpeed: 900,
    },
    buttons: {
      backgroundColor: '#3aaa35',
      showDownloadButton: false,
      iconColor: 'rgba(255, 255, 255, 0.8)',
    },
    caption: {
      captionColor: '#fff',
      captionFontFamily: 'Raleway, sans-serif',
      captionFontWeight: '500',
      captionContainerPadding: '15px',
    },
    thumbnails: {
      showThumbnails: false,
    },
  }

  return (
    <Layout>
      <GalleryStyles>
        <section>
          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              {gallery.nodes.map((photo, i) => (
                <SingleImage data={photo} key={i} i={i} />
              ))}
            </SRLWrapper>
          </SimpleReactLightbox>
        </section>
      </GalleryStyles>
    </Layout>
  )
}

export default GalleryAgain

const SingleImage = ({ data, i }) => (
  <SinglePhotoStyles id={i}>
    <a href={data.desktopImage.childrenImageSharp[0].fluid.src}>
      <GetImage
        data={data.thumbnail}
        alt={`${data.bio} - © ${data.photographer}`}
        srl_gallery_image="true"
      />
      <div className="overlay">
        <div>
          <h5>{data.name}</h5>
          {data.photographer ? (
            <p>{`Photograph by ${data.photographer}`}</p>
          ) : (
            ''
          )}
        </div>
      </div>
    </a>
  </SinglePhotoStyles>
)

export const GalleryAgainQuery = graphql`
  query GalleryAgain {
    gallery: allGalleryJson {
      nodes {
        name
        bio
        photographer
        thumbnail {
          childImageSharp {
            gatsbyImageData(
              width: 400
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        desktopImage {
          childrenImageSharp {
            fluid(quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`
